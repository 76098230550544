


















































import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import api from "@/api";
import {
  DataDictionaryDto,
  PartnerInviteCreateOrUpdateDto,
} from "@/api/appService";
import validate from "@/utils/validate";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({ name: "EditPartnerInvite", components: { AbSelect } })
export default class EditPartnerInvite extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  defaultData: PartnerInviteCreateOrUpdateDto = {
    organizationName: undefined,
    emailAddress: undefined,
    mobile: undefined,
    content: undefined,
    id: 0,
  };

  show = false;
  form: PartnerInviteCreateOrUpdateDto = { ...this.defaultData };

  orgTypeList: DataDictionaryDto[] | undefined = [];

  async created() {
    await this.fetchDataDictionary();
  }
  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "OrganizationUnitType",
        maxResultCount: 1000,
      })
      .then((res) => {
        this.orgTypeList = res.items;
      });
  }

  get dialogTitle() {
    return this.form!.id ? "编辑" : "新建";
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      if (this.dataId) {
        await api.partnerInvite.get({ id: this.form!.id }).then((res) => {
          this.form = { ...res };
        });
      } else {
        this.form = { ...this.defaultData };
      }
    } else {
      this.form = { ...this.defaultData };
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    this.dataForm.validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.partnerInvite.update({ body: this.form });
        } else {
          await api.partnerInvite.create({ body: this.form });
        }
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  partnerInviteRule = {
    organizationName: [
      {
        required: true,
        message: "机构名称必填",
        trigger: ["blur", "change"],
      },
    ],
    organizationType: [
      {
        required: true,
        message: "合作方类型必填",
        trigger: ["blur", "change"],
      },
    ],
    emailAddress: [
      { required: true, message: "邮件地址必填", trigger: ["blur", "change"] },
    ],
    mobile: [
      {
        required: true,
        trigger: ["blur", "change"],
        validator: validate.validPhone,
      },
    ],
    // content: [
    //   {required: true, message: '邀约内容必填', trigger: ['blur', 'change']}
    // ]
  };
}
