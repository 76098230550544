



































































import { Vue } from "vue-property-decorator";

export default class UploadTemplateResult extends Vue {
  table: any = this.$route.params.table;
  detail: any;

  created() {
    this.table = this.$route.params.table;
  }

  back() {
    this.$router.go(-1);
  }
}
